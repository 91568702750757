import $ from 'jquery';
import 'slick-carousel';

class TopSlider {
  constructor() {
    this.$slider = $('.js-top-heroSlider');
    if (this.$slider.length > 0) {
      this.init();
    }
  }

  init() {
    this.setSlick();
  }

  setSlick() {
    this.$slider.slick({
      fade: true,
      autoplay: true,
      speed: 500,
      autoplaySpeed: 2500,
      dots: true,
      arrows: false,
      draggable: true,
    });
  }
}

export default TopSlider;

import $ from 'jquery';
import 'slick-carousel';

class PerformanceSlider {
  constructor() {
    this.$slider = $('.js-top-performanceSlider');
    if (this.$slider.length > 0) {
      this.init();
    }
  }

  init() {
    this.setSlick();
  }

  setSlick() {
    this.$slider.slick({
      infinite: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      prevArrow: '<img src="./assets/images/icon-prev.png" alt="" class="p-top-performanceSliderArrow">',
      nextArrow: '<img src="./assets/images/icon-next.png" alt="" class="p-top-performanceSliderArrow p-top-performanceSliderArrow--next">',
      responsive: [{
        breakpoint: 767,
        settings: {
          slidesToShow: 1
        }
      }]
    });
  }
}

export default PerformanceSlider;

import $ from 'jquery';

import '../styles/index.scss'; // webpackでコンパイルするため必須. 実際には.jsには展開されない.

import TopSlider from './components/TopSlider';
import PerformanceSlider from './components/PerformanceSlider';
import Price from './components/Price';

$(document).ready(() => {
  new TopSlider();
  new PerformanceSlider();
  new Price();
});

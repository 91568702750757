import $ from 'jquery';

class Price {
  constructor() {
    this.$container = $('.js-price');
    this.$select = $('.js-top-priceSelect');
    this.$selectType = $('#workshop-type');
    this.$selectCount = $('#workshop-count');
    this.$priceResult = $('#price-result');
    this.$priceTotal = $('#price-total');
    this.$priceFriend = $('#price-friend');
    this.$priceDance = $('#price-dance');
    this.$note = $('#workshop-note');
    this.$overlay = $('.js-top-priceCalcOverlay');
    if (this.$container.length > 0) {
      this.addEvent();
    }
  }

  addEvent() {
    this.$select.change(() => {
      const type = this.$selectType.val();
      const count = Number(this.$selectCount.val());
      if( type != "" && count != "") {
        var valueFriend = 0;
        var valueDance = 0;
        if(type === 'real') {
          switch(count) {
            case 30:
              valueFriend = 180000;
              valueDance = 150000;
              break;
            case 40:
              valueFriend = 205000;
              valueDance = 160000;
              break;
            case 50:
              valueFriend = 250000;
              valueDance = 175000;
              break;
            case 60:
              valueFriend = 300000;
              valueDance = 210000;
              break;
            case 70:
              valueFriend = 350000;
              valueDance = 245000;
              break;
            case 80:
              valueFriend = 400000;
              valueDance = 280000;
              break;
          }
          this.$note.text('上記に加え、右記料金が発生します…テキスト（500円）×参加人数分、機材配送費、著作権使用料、講師の移動にかかる交通費');
        } else {
          switch(count) {
            case 30:
              valueFriend = 180000;
              valueDance = 150000;
              break;
            case 40:
              valueFriend = 190000;
              valueDance = 160000;
              break;
            case 50:
              valueFriend = 250000;
              valueDance = 175000;
              break;
            case 60:
              valueFriend = 300000;
              valueDance = 210000;
              break;
            case 70:
              valueFriend = 350000;
              valueDance = 245000;
              break;
            case 80:
              valueFriend = 400000;
              valueDance = 280000;
              break;
          }
          this.$note.text('上記に加え、右記料金が発生します…著作権使用料');
        }
        valueFriend = valueFriend * 1.1;
        valueDance = valueDance * 1.1;
        const valueTotal = valueFriend + valueDance;
        const valueResult = (valueFriend + valueDance) * 0.9;
        this.$priceTotal.text(valueTotal.toLocaleString() + '円');
        this.$priceResult.text(valueResult.toLocaleString() + '円');
        this.$priceFriend.text(valueFriend.toLocaleString() + '円');
        this.$priceDance.text(valueDance.toLocaleString() + '円');
        this.$overlay.fadeOut(500);
      } else {
        this.$overlay.fadeIn(500);
      }
    });
  }
}

export default Price;
